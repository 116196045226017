import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CashTitle } from 'src/app/interface/cash-title';

@Component({
  selector: 'app-cant-cancel-invoice-warning',
  templateUrl: './cant-cancel-invoice-warning.component.html',
  styleUrl: './cant-cancel-invoice-warning.component.scss'
})
export class CantCancelInvoiceWarningComponent {

  titles: CashTitle[];
  columns = ['titleNumber', 'clientSupplier', 'totalLows', 'value', 'balance'];
  
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: CashTitle[]
  ) {
    if (data) {
      this.titles = data;
    } 
  }
}
