<h2 mat-dialog-title>Títulos que possuem baixas ativas</h2>
<mat-dialog-content>
  <table mat-table [dataSource]="titles" class="mat-elevation-z8">
    <ng-container matColumnDef="titleNumber">
      <th mat-header-cell *matHeaderCellDef>Nº do título</th>
      <td mat-cell *matCellDef="let title"> {{ title.invoiceNumber }} </td>
    </ng-container>

    <ng-container matColumnDef="clientSupplier">
      <th mat-header-cell *matHeaderCellDef>Cliente/Fornecedor</th>
      <td mat-cell *matCellDef="let title" class="text-center"> {{ title.supplier_client?.name }} </td>
    </ng-container>

    <ng-container matColumnDef="totalLows">
      <th mat-header-cell *matHeaderCellDef>Qtd. de baixas</th>
      <td mat-cell *matCellDef="let title"> {{ title.received.length }} </td>
    </ng-container>

    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef>Valor</th>
      <td mat-cell *matCellDef="let title"> {{ title.value | currency:'BRL' }} </td>
    </ng-container>

    <ng-container matColumnDef="balance">
      <th mat-header-cell *matHeaderCellDef>Saldo</th>
      <td mat-cell *matCellDef="let title"> {{ title.balance | currency:'BRL' }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row;columns: columns"></tr>
  </table>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" mat-dialog-close cdkFocusInitial>Ok</button>
</mat-dialog-actions>
